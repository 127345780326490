import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Box } from 'grommet'
import { Button, IconButton } from 'nzk-react-components'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { useContainer } from 'unstated-next'
// @ts-ignore
import { ReactComponent as Tick } from '../assets/images/icons/icon-tick.svg'
import Input from '../components/UI/Input'
import UnauthenticatedLayout from '../layouts/UnauthenticatedLayout'
import { CurrentUserState } from '../state/CurrentUserState'
import bgImg from '../assets/images/LoginBackground.jpg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  > * {
    margin-bottom: 30px;
  }
  background-image: url('${bgImg}');
  background-size: cover;
  background-position: center;
  padding-top: 50px;
  padding-bottom: 50px;
  > :last-child {
    margin-bottom: 0;
  }
`

const StyledBox = styled(Box)`
  position: relative;
  width: 350px;
  box-shadow: 0 -3px 0 #481762, 0 5px 0 #1b0325, 0 9px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  background-color: #2f143a;
  > :first-child {
    letter-spacing: 1.1;
    font-weight: bold;
    font-size: 21px;
  }

  > * {
    margin-bottom: 15px;
  }

  > :last-child {
    margin-bottom: 0;
    color: #fff;
  }
`

const ChildLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Field = styled.div``

const Label = styled.div`
  font-family: 'Rammetto One';
  margin-bottom: 5px;
`

const Title = styled.div`
  color: #fff;
  min-height: 40px;
  width: 100%;
  font-family: 'Rammetto One', cursive;
  text-shadow: 0 3px 0 #afafaf, 0 6px 0 rgba(0, 0, 0, 0.4);
  text-align: center;
  h1 {
    font-size: 21px;
  }
`

const Actions = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  margin-top: 8px;
  a {
    margin-top: 15px;
    color: #fff;
  }
`

const Error = styled.div`
  color: #ef3b30;
`

const LoginPage = () => {
  const passwordRef = useRef()
  const emailRef = useRef()
  const location = useLocation()
  const { login } = useContainer(CurrentUserState)

  const [error, setError] = useState()

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    emailRef.current.value = urlParams.get('email')
  }, [])

  const submitLogin = async () => {
    setError()
    const urlParams = new URLSearchParams(location.search)

    try {
      await login(emailRef.current.value, passwordRef.current.value)
      if (urlParams.get('redirect')) {
        navigate(`${urlParams.get('redirect')}?${urlParams.toString()}`)
      }
    } catch (err) {
      if (typeof window !== 'undefined' && window.Sentry) {
        window.Sentry.configureScope((scope) => {
          scope.setUser({
            email: emailRef.current.value,
          })
        })
        if (err.raw) {
          window.Sentry.captureException(err.raw)
        } else {
          window.Sentry.captureException(err)
        }
      }
      setError(err.message)
    }
  }

  const onChildLogin = async () => {
    if (typeof window === 'undefined') return
    setTimeout(() => {
      window.location.assign('https://nightzookeeper.com/edu/zoo/logout')
    }, 100)
  }

  return (
    <Wrapper>
      <Helmet title="Parent & Teacher Dashboard Login">
        <meta
          name="description"
          content="Log in to your dashboard to view progress, manage your account and assign activities"
        />
      </Helmet>
      <StyledBox elevation="medium" pad="medium" round="small">
        <Title>
          <h1>Dashboard Login</h1>
        </Title>
        {error && <Error>{error}</Error>}
        {/* eslint-disable-next-line */}
        <Field>
          <Label>Email</Label>
          <Input
            ref={emailRef}
            type="email"
            defaultValue=""
            autoComplete="username"
            placeholder="e.g. will@night.zoo"
            onKeyPress={(e) => e.which === 13 && passwordRef.current.focus()}
          />
        </Field>
        {/* eslint-disable-next-line */}
        <Field>
          <Label>Password</Label>
          <Input
            ref={passwordRef}
            type="password"
            defaultValue=""
            autoComplete="password"
            placeholder="e.g. ********"
            onKeyPress={(e) => e.which === 13 && submitLogin()}
          />
        </Field>
        <Actions>
          <IconButton
            onClick={submitLogin}
            icon={<Tick color="#8CC63F" />}
            theme="confirm"
            size="small"
          >
            Submit
          </IconButton>
          <a href="https://www.nightzookeeper.com/edu/forgot-password">
            Forgotten your password?
          </a>
        </Actions>
      </StyledBox>
      <StyledBox elevation="medium" pad="medium" round="small">
        <ChildLogin>
          <Title>
            <div>Login as a child</div>
          </Title>
          <Button backgroundColor="#66139A" onClick={onChildLogin} size="small">
            Child Login
          </Button>
        </ChildLogin>
      </StyledBox>
      <StyledBox elevation="medium" pad="medium" round="small">
        <ChildLogin>
          <Title>
            <div style={{ marginBottom: '15px' }}>Don't have an account?</div>
          </Title>
          <Button
            backgroundColor="#66139A"
            onClick={() => {
              if (typeof window !== 'undefined') {
                window.location.assign('https://nightzookeeper.com/parents')
              }
            }}
            size="small"
          >
            Start a free trial
          </Button>
        </ChildLogin>
      </StyledBox>
    </Wrapper>
  )
}

const LoginPageExport = () => {
  if (typeof window === 'undefined') return null
  return (
    <UnauthenticatedLayout>
      <LoginPage />
    </UnauthenticatedLayout>
  )
}

export default LoginPageExport
